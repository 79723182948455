// Framer Override to block page scrolling but allow scrolling within overlay
export default function BlockPageScrollOverride(props) {
    const { isOpen } = props

    React.useEffect(() => {
        // When overlay opens
        if (isOpen) {
            // Store the current scroll position
            const scrollY = window.scrollY

            // Apply styles to body to prevent page scrolling
            // but don't use overflow: hidden which would affect all scrollable elements
            document.body.style.position = "fixed"
            document.body.style.top = `-${scrollY}px`
            document.body.style.width = "100%"
            // Don't modify the overflowY property to allow inner scrollable elements to work
        }
        // When overlay closes
        else {
            // Get the scroll position from body's top property
            const scrollY = document.body.style.top

            // Remove the fixed positioning
            document.body.style.position = ""
            document.body.style.top = ""
            document.body.style.width = ""

            // Restore scroll position
            window.scrollTo(0, parseInt(scrollY || "0") * -1)
        }

        // Cleanup function
        return () => {
            document.body.style.position = ""
            document.body.style.top = ""
            document.body.style.width = ""
        }
    }, [isOpen])

    // Return the children/content unchanged
    return props.children
}
